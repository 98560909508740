import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "userText" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "plat_title" }
const _hoisted_4 = { class: "desc" }
const _hoisted_5 = { class: "download_out" }
const _hoisted_6 = { style: {"font-weight":"600"} }
const _hoisted_7 = { class: "desc" }
const _hoisted_8 = { class: "desc" }

import { lang } from '@/lang';
import updownBlack from '@/assets/img/newHome/updownBlack.png';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFeedback',
  setup(__props) {

const userAgent = navigator.userAgent;
// 下载
const onDownload = () => {
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    // 是iOS设备
    window.open(window.download.ios, '_blank');
  } else if (/android/i.test(userAgent)) {
    // 是Android设备
    window.open(window.download.android, '_blank');
  } else {
    window.open(window.download.android, '_blank');
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(lang).v.RPlatfromPro), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(lang).v.RPlatfromTitle), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(lang).v.RPlatformDescPro), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        onClick: onDownload,
        class: "download"
      }, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(lang).v.RContentNow), 1)
      ])
    ]),
    _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(lang).v.RMore), 1),
    _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(lang).v.RContactUs), 1)
  ], 64))
}
}

})