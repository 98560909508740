import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "social_media flex_column_center_center" }
const _hoisted_2 = { class: "top flex_center" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "buttom" }
const _hoisted_5 = { class: "list flex_center_center" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "text" }

import { lang } from '@/lang';
enum mediahref {
  x = 'https://x.com/ReelDAO_Lab',
  tg = 'https://t.me/ReelDAO_Official',
  Instagram = 'https://www.instagram.com/reeldao1',
  Medium = 'https://medium.com/@ReelDAO',
}

export default /*@__PURE__*/_defineComponent({
  __name: 'SocialMedia',
  setup(__props) {

const mediaList = [
  {
    icon: require('@/assets/img/socialMedia/X.png'),
    href: mediahref.x,
    name: 'X',
  },
  {
    icon: require('@/assets/img/socialMedia/Telegram.png'),
    href: mediahref.tg,
    name: 'Telegram',
  },
  {
    icon: require('@/assets/img/socialMedia/Medium.png'),
    href: mediahref.Medium,
    name: 'Medium',
  },
  {
    icon: require('@/assets/img/socialMedia/Instagram.png'),
    href: mediahref.Instagram,
    name: 'Instagram',
  },
];
const goHref = (item: (typeof mediaList)[0]) => {
  // window.location.href = item.href;
  window.open(item.href);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "line" }, null, -1)),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(lang).v.socialMedia), 1),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "line" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(mediaList, (item) => {
          return _createElementVNode("div", {
            class: "item flex_column_center",
            key: item.icon,
            onClick: ($event: any) => (goHref(item))
          }, [
            _createElementVNode("img", {
              class: "icon",
              src: item.icon,
              alt: ""
            }, null, 8, _hoisted_7),
            _createElementVNode("span", _hoisted_8, _toDisplayString(item.name), 1)
          ], 8, _hoisted_6)
        }), 64))
      ])
    ])
  ]))
}
}

})