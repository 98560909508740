import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }

import PopularizeTop from '@/components/NewTop/index.vue';
import DownLoad from './components/DownLoad.vue';
import MarketBackground from './components/MarketBackground.vue';
import ProductFeatures from './components/ProductFeatures.vue';
import UserFeedback from './components/UserFeedback.vue';
import CompetitiveAdvantages from './components/CompetitiveAdvantages.vue';
import BottomBack from './components/BottomBack.vue';
import SocialMedia from './components/SocialMedia.vue';
import ScrollReveal from 'scrollreveal';
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

onMounted(() => {
  const download = document.querySelector('.download_section') as HTMLElement;
  if (download) {
    // 设置初始样式
    download.style.transform = 'translateY(-100px)';
    download.style.opacity = '0';
    download.style.transition =
      'transform 1s ease-in-out, opacity 1s ease-in-out';
    setTimeout(() => {
      download.style.transform = 'translateY(0)';
      download.style.opacity = '1';
    }, 100);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PopularizeTop),
    _createVNode(DownLoad, { class: "download_section" }),
    _createVNode(MarketBackground, { class: "market_background" }),
    _createVNode(ProductFeatures, { class: "product_features" }),
    _createVNode(CompetitiveAdvantages, { class: "competitive_advantages" }),
    _createVNode(UserFeedback, { class: "user_feedback" }),
    _createVNode(SocialMedia),
    _createVNode(BottomBack)
  ]))
}
}

})