import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "top" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "dropdown_lang"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "dropdown_menu"
}
const _hoisted_8 = ["onClick"]

import menuUrl from '@/assets/img/newHome/menu.png';
import langUrl from '@/assets/img/newHome/lang.png';
import { lang } from '@/lang/index';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';

import { onDownload } from '@/utils/download';
import { fetchLanguageList } from '@/api/fetcher';
import dataCenter from '@/dataCenter';
import { LanguageListResp } from '@/api/protocol';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    type: {}
  },
  setup(__props: any) {

const route = useRoute();
const props = __props;
//lang选择
const isOpen = ref(false);

const onSelect = (item: LanguageListResp) => {
  lang.change(item.abbreviation);
  isOpen.value = false;
  isOpenMask.value = false;
};
// 菜单选择
const isOpenMenu = ref(false);
const itemsMenu = [
  {
    key: 'Home',
    text: 'ReelDao',
  },
  {
    key: 'Doc',
    text: computed(() => lang.v.Doc),
  },
  {
    key: 'Contact',
    text: computed(() => lang.v.Contact),
  },
  {
    key: 'Download',
    text: computed(() => lang.v.download),
  },
];
const LanguageList = dataCenter.language;
const getLanguageList = () => {
  fetchLanguageList({ status: 1 }).then(res => {
    if (res) {
      LanguageList.save(res);
    }
  });
};

onMounted(() => {
  if (!LanguageList.data?.length) {
    getLanguageList();
  }
});

const selectItemMenu = (item: string) => {
  if (item == 'Doc') {
    window.open('https://reeldao.gitbook.io/reeldao-global/');
    return;
  }
  if (item != 'Download') {
    router.push({ name: item });
  } else {
    onDownload();
  }
};

//遮罩层
const isOpenMask = ref(false);
const open = (type: number) => {
  if (type === 1) {
    isOpen.value = !isOpen.value;
    isOpenMenu.value = false;
  } else if (type === 2) {
    isOpenMenu.value = !isOpenMenu.value;
    isOpen.value = false;
  } else {
    isOpen.value = false;
    isOpenMenu.value = false;
  }
  if (isOpen.value || isOpenMenu.value) {
    isOpenMask.value = true;
  } else {
    isOpenMask.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!props?.type)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: _unref(langUrl),
              alt: "",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (open(1)), ["stop"]))
            }, null, 8, _hoisted_3),
            (isOpen.value)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(LanguageList).data, (item) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: _normalizeClass(item?.abbreviation === _unref(lang).m ? 'active' : ''),
                      key: item.id,
                      onClick: ($event: any) => (onSelect(item))
                    }, _toDisplayString(item.name), 11, _hoisted_5))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: _unref(menuUrl),
          alt: "",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (open(2)), ["stop"]))
        }, null, 8, _hoisted_6),
        (isOpenMenu.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(itemsMenu, (item) => {
                return _createElementVNode("li", {
                  key: item.key,
                  onClick: ($event: any) => (selectItemMenu(item.key)),
                  class: _normalizeClass(item.key === _unref(route).name ? 'active' : '')
                }, _toDisplayString(item.text), 11, _hoisted_8)
              }), 64))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (isOpenMask.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mask",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (open(0)))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})